import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import styles from "./SuccessPage.module.css";

const SuccessPage: React.FC = () => {
  const [sessionId, setSessionId] = useState<string | null>(null);
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const session_id = searchParams.get("session_id");
    setSessionId(session_id);
  }, [location]);

  return (
    <div className={styles.successPage}>
      <div className={styles.successContent}>
        <FaCheckCircle className={styles.checkIcon} />
        <h1>Mulțumim pentru comanda dumneavoastră!</h1>
        <p className={styles.mainMessage}>Plata a fost procesată cu succes.</p>
        <div className={styles.orderInfo}>
          <p>Numărul de referință al comenzii:</p>
          <p className={styles.orderId}>{sessionId}</p>
        </div>
        <div className={styles.nextSteps}>
          <h2>Ce urmează?</h2>
          <ol>
            <li>Veți primi în curând un email cu detaliile comenzii.</li>
            <li>Artistul nostru va începe lucrul la comanda dumneavoastră.</li>
            <li>Vă vom ține la curent cu progresul comenzii prin email.</li>
            <li>Comanda va fi expediată în cel mult 24 de ore.</li>
          </ol>
        </div>
        <p className={styles.contact}>
          Dacă aveți întrebări, nu ezitați să ne contactați la{" "}
          <a href="mailto:contact@chinart.ro">contact@chinart.ro</a>.
        </p>
        <a href="/" className={styles.backButton}>
          Înapoi la pagina principală
        </a>
      </div>
    </div>
  );
};

export default SuccessPage;
