import React, { useState, FormEvent, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { createCheckoutSession } from "../../services/api";
import styles from "./CheckoutForm.module.css";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ""
);

interface OrderData {
  name: string;
  email: string;
  product: string;
  nameToTranslate: string;
  gender: string;
  orientation: string;
  comments: string;
}

const CheckoutForm: React.FC = () => {
  const [orderData, setOrderData] = useState<OrderData>({
    name: "",
    email: "",
    product: "",
    nameToTranslate: "",
    gender: "",
    orientation: "",
    comments: "",
  });
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCanceled, setIsCanceled] = useState<boolean>(false);
  const location = useLocation();
  const nameInputRef = useRef<HTMLInputElement>(null);

  const focusOnNameInput = () => {
    if (nameInputRef.current) {
      nameInputRef.current.focus();
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const canceled = searchParams.get("canceled");
    setIsCanceled(canceled === "true");

    const selectedProduct = localStorage.getItem("selectedProduct");
    if (selectedProduct) {
      setOrderData((prevData) => ({ ...prevData, product: selectedProduct }));
      localStorage.removeItem("selectedProduct");
      focusOnNameInput();
    }

    const handleProductSelected = (event: CustomEvent) => {
      setOrderData((prevData) => ({ ...prevData, product: event.detail }));
      focusOnNameInput();
    };

    const handleFocusNameInput = () => {
      focusOnNameInput();
    };

    window.addEventListener(
      "productSelected",
      handleProductSelected as EventListener
    );
    window.addEventListener("focusNameInput", handleFocusNameInput);

    return () => {
      window.removeEventListener(
        "productSelected",
        handleProductSelected as EventListener
      );
      window.removeEventListener("focusNameInput", handleFocusNameInput);
    };
  }, [location]);

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setOrderData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setIsCanceled(false);

    try {
      const { sessionId } = await createCheckoutSession(orderData);
      const stripe = await stripePromise;
      if (!stripe) {
        throw new Error("Failed to load Stripe");
      }

      const { error } = await stripe.redirectToCheckout({ sessionId });
      if (error) {
        throw error;
      }
    } catch (error) {
      console.error("Error:", error);
      setError(
        error instanceof Error
          ? error.message
          : "An unexpected error occurred. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const isParchment = orderData.product === "pergament_imperial";

  return (
    <form onSubmit={handleSubmit} className={styles.checkoutForm}>
      {isCanceled && (
        <div className={styles.warning}>
          Comanda a fost anulată. Puteți încerca din nou dacă doriți.
        </div>
      )}
      <div className={styles.formGroup} style={{ marginRight: "1.5rem" }}>
        <label htmlFor="name">Nume și Prenume</label>
        <input
          id="name"
          name="name"
          value={orderData.name}
          onChange={handleInputChange}
          required
          ref={nameInputRef}
        />
        <p className={styles.fieldDescription}>
          Te rugăm să scrii numele și prenumele persoanei care plasează comanda.
        </p>
      </div>
      <div className={styles.formGroup} style={{ marginRight: "1.5rem" }}>
        <label htmlFor="email">Email</label>
        <input
          id="email"
          name="email"
          type="email"
          value={orderData.email}
          onChange={handleInputChange}
          required
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="product">Produs</label>
        <select
          id="product"
          name="product"
          value={orderData.product}
          onChange={handleInputChange}
          required
        >
          <option value="">Selectează produsul</option>
          <option value="amintire_caligrafica_clasica">
            Amintire Caligrafică Clasică - 199 lei
          </option>
          <option value="amintire_caligrafica_grand">
            Amintire Caligrafică Grand - 299 lei
          </option>
          <option value="pergament_imperial">
            Pergament Imperial - 399 lei
          </option>
          <option value="armonie_compacta">Armonie Compactă - 349 lei</option>
          <option value="armonie_echilibrata">
            Armonie Echilibrată - 649 lei
          </option>
          <option value="armonie_impunatoare">
            Armonie Impunătoare - 1049 lei
          </option>
        </select>
      </div>
      <div className={styles.formGroup} style={{ marginRight: "1.5rem" }}>
        <label htmlFor="nameToTranslate">Nume de tradus</label>
        <input
          id="nameToTranslate"
          name="nameToTranslate"
          value={orderData.nameToTranslate}
          onChange={handleInputChange}
          required
        />
        <p className={styles.fieldDescription}>
          Te rugăm să scrii numele și prenumele persoanei pentru care se face
          transcrierea. În felul acesta artistul nostru poate face o adaptare
          cât mai apropiată de un nume chinezesc autentic.
        </p>
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="gender">Gen</label>
        <select
          id="gender"
          name="gender"
          value={orderData.gender}
          onChange={handleInputChange}
          required
        >
          <option value="">Selectează genul</option>
          <option value="male">Masculin</option>
          <option value="female">Feminin</option>
        </select>
      </div>
      {!isParchment && (
        <div className={styles.formGroup}>
          <label htmlFor="orientation">Orientare</label>
          <select
            id="orientation"
            name="orientation"
            value={orderData.orientation}
            onChange={handleInputChange}
            required
          >
            <option value="">Selectează orientarea</option>
            <option value="horizontal">Orizontală</option>
            <option value="vertical">Verticală</option>
          </select>
          <p className={styles.fieldDescription}>
            Vă rugăm să specificați dacă doriți scrierea pe orizontală sau pe
            verticală. Pentru pergament scrierea se face întotdeauna pe
            verticală.
          </p>
        </div>
      )}
      <div className={styles.formGroup} style={{ marginRight: "1.5rem" }}>
        <label htmlFor="comments">Comentarii adiționale</label>
        <textarea
          id="comments"
          name="comments"
          value={orderData.comments}
          onChange={handleInputChange}
        />
      </div>{" "}
      <div className={styles.packagingDeliverySummary}>
        <h3>Ambalare și Livrare</h3>
        <p>
          Comanda ta va fi ambalată cu grijă într-un tub elegant și livrată în
          maxim 24 de ore. Livrarea este gratuită pe tot teritoriul României.
        </p>
        <a href="#ambalare-si-livrare" className={styles.moreInfoLink}>
          Află mai multe despre ambalare și livrare
        </a>
      </div>
      {error && <div className={styles.error}>{error}</div>}
      <button
        type="submit"
        disabled={isLoading}
        className={styles.submitButton}
      >
        {isLoading ? "Se procesează..." : "Continuă spre plată"}
      </button>
    </form>
  );
};

export default CheckoutForm;
