import React from "react";
import styles from "./Hero.module.css";
import { scrollToCommandaAndFocus } from "../../utils/focusUtilts";

const Hero: React.FC = () => {
  const handleCommandaClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    scrollToCommandaAndFocus();
  };

  const handleAflaClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const despreSection = document.getElementById("despre");
    if (despreSection) {
      despreSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div id="hero-anchor" className={styles.heroAnchor}></div>
      <section className={styles.hero}>
        <div className={styles.heroOverlay}></div>
        <div className={styles.heroContent}>
          <h1>Numele Tău în Caligrafie Chineză Autentică</h1>
          <p>
            Descoperă frumusețea numelui tău scris de maeștri caligrafiei
            chineze.
            <br />
            Un cadou unic și plin de semnificație.
          </p>
          <div className={styles.ctaContainer}>
            <a
              href="#comanda"
              className={styles.ctaButton}
              onClick={handleCommandaClick}
            >
              Comandă Acum
            </a>
            <a
              href="#despre"
              className={styles.learnMoreButton}
              onClick={handleAflaClick}
            >
              Află Mai Multe
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
