import React from "react";
import styles from "./About.module.css";

const About: React.FC = () => {
  return (
    <section id="despre" className={styles.about}>
      <div className={styles.content}>
        <h2>Despre ChinArt</h2>
        <p>
          La ChinArt, transformăm numele în opere de artă unice. Numele tău va
          fi tradus și interpretat în caractere chinezești și va fi pictat
          manual de artiștii noștri talentați, maeștri în caligrafia chineză,
          oferind o interpretare autentică și plină de semnificație.
        </p>
      </div>
    </section>
  );
};

export default About;
