import React from "react";
import { Link } from "react-router-dom";
import styles from "./Footer.module.css";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaPinterestP,
} from "react-icons/fa";

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.footer}>
      <div className={styles.socialIcons}>
        <a
          href="https://facebook.com"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Facebook"
        >
          <FaFacebookF />
        </a>
        <a
          href="https://instagram.com"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Instagram"
        >
          <FaInstagram />
        </a>
        <a
          href="https://linkedin.com"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="LinkedIn"
        >
          <FaLinkedinIn />
        </a>
        <a
          href="https://pinterest.com"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Pinterest"
        >
          <FaPinterestP />
        </a>
      </div>
      <p className={styles.copyright}>© {currentYear} ChinArt</p>
      <nav className={styles.footerNav}>
        <Link to="/termeni-si-conditii">Termeni și condiții</Link>
        <Link to="/politica-de-confidentialitate">
          Politica de confidențialitate
        </Link>
        <a href="https://anpc.ro/" target="_blank" rel="noopener noreferrer">
          ANPC
        </a>
      </nav>
    </footer>
  );
};

export default Footer;
