export const scrollToCommandaAndFocus = () => {
  const comandaSection = document.getElementById("comanda");
  if (comandaSection) {
    // Smooth scroll to the comanda section
    comandaSection.scrollIntoView({ behavior: "smooth" });

    // Focus on the name input after scrolling is complete
    setTimeout(() => {
      const event = new Event("focusNameInput");
      window.dispatchEvent(event);
    }, 500); // Adjust this delay if needed to ensure scrolling is complete
  }
};

export const focusNameInput = () => {
  const event = new Event("focusNameInput");
  window.dispatchEvent(event);
};

export const scrollToSection = (sectionId: string) => {
  const section = document.getElementById(sectionId);
  if (section) {
    section.scrollIntoView({ behavior: "smooth" });
  }
};
