import React from "react";
import CheckoutForm from "./CheckoutForm";
import styles from "./Order.module.css";

function Order() {
  return (
    <section id="comanda" className={styles.order}>
      <div className={styles.content}>
        <h2>Comandă Acum</h2>
        <CheckoutForm />
      </div>
    </section>
  );
}

export default Order;
