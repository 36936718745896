import React from "react";
import styles from "./PackagingAndDelivery.module.css";

const PackagingAndDelivery: React.FC = () => {
  return (
    <section id="ambalare-si-livrare" className={styles.packagingAndDelivery}>
      <div className={styles.container}>
        <h2>Ambalare și Livrare</h2>
        <div className={styles.content}>
          <div className={styles.packaging}>
            <h3>Ambalare și Înrămare</h3>
            <p>
              Avem grijă ca fiecare comandă să fie livrată într-un tub elegant
              pentru a fi protejată în timpul transportului. După ce vei desface
              cu grijă tubul, vei găsi hârtia caligrafică, învelită într-o
              hârtie de protecție. După ce ai înlăturat protecția, poți înrăma
              opera. Hârtia caligrafică este subțire și are nevoie de fixare pe
              passe-partout; așadar, asigură-te că este montată pe un suport de
              carton alb și apoi înrămată.
            </p>
          </div>
          <div className={styles.delivery}>
            <h3>Livrare</h3>
            <p>
              Transportul este inclus în preț. <br /> Livrarea este disponibilă
              doar pe teritoriul României. <br />
              Produsul va fi expediat către tine în cel mult 24 de ore
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PackagingAndDelivery;
