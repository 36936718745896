// src/services/api.ts

interface OrderData {
  name: string;
  email: string;
  product: string;
  nameToTranslate: string;
  gender: string;
  orientation: string;
  comments: string;
}

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL ||
  "http://localhost:5001/chinart-e000e/europe-west1";

export async function createCheckoutSession(
  orderData: OrderData
): Promise<{ sessionId: string }> {
  const response = await fetch(`${API_BASE_URL}/createCheckoutSession`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(orderData),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(
      errorData.error || `HTTP error! status: ${response.status}`
    );
  }

  return response.json();
}
