import React from "react";
import {
  Edit,
  Palette,
  Sparkles,
  Truck,
  ArrowRight,
  ArrowDown,
} from "lucide-react";
import styles from "./Process.module.css";

interface Step {
  Icon: React.ElementType;
  title: string;
  description: string;
}

const Process: React.FC = () => {
  const steps: Step[] = [
    {
      Icon: Edit,
      title: "1. Alegi Numele",
      description:
        "Completezi numele pe care dorești să-l transformăm în artă.",
    },
    {
      Icon: Palette,
      title: "2. Artistul Creează",
      description: "Maestrul nostru caligraf traduce și interpretează numele.",
    },
    {
      Icon: Sparkles,
      title: "3. Finisare",
      description: "Lucrarea este împachetată și pregătită pentru expediere.",
    },
    {
      Icon: Truck,
      title: "4. Livrare",
      description: "Primești arta unică direct la ușa ta.",
    },
  ];

  return (
    <section id="proces" className={styles.process}>
      <div className={styles.container}>
        <h2 className={styles.title}>Procesul Nostru</h2>
        <div className={styles.stepsContainer}>
          {steps.map(({ Icon, title, description }, index) => (
            <React.Fragment key={index}>
              <div className={styles.step}>
                <div className={styles.stepContent}>
                  <div className={styles.iconContainer}>
                    <Icon className={styles.icon} />
                  </div>
                  <h3 className={styles.stepTitle}>{title}</h3>
                  <p className={styles.stepDescription}>{description}</p>
                </div>
              </div>
              {index < steps.length - 1 && (
                <div className={styles.arrowContainer}>
                  <ArrowRight className={styles.arrowHorizontal} />
                  <ArrowDown className={styles.arrowVertical} />
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Process;
