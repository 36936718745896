import React from "react";
import styles from "./Testimonials.module.css";

interface Testimonial {
  text: string;
  author: string;
}

const Testimonials: React.FC = () => {
  const testimonials: Testimonial[] = [
    {
      text: "Am comandat o caligrafie pentru aniversarea părinților mei. Au fost încântați de frumusețea și unicitatea cadoului. Mulțumesc, ChinArt!",
      author: "Maria D.",
    },
    {
      text: "Un cadou perfect pentru prietena mea pasionată de cultura asiatică. Calitatea lucrării este excepțională!",
      author: "Andrei V.",
    },
    {
      text: "Serviciul clienti a fost extraordinar, iar produsul a depășit toate așteptările. Recomand cu încredere!",
      author: "Elena M.",
    },
  ];

  return (
    <section id="testimoniale" className={styles.testimonials}>
      <div className={styles.content}>
        <h2>Ce Spun Clienții Noștri</h2>
        <div className={styles.testimonialGrid}>
          {testimonials.map((testimonial, index) => (
            <div key={index} className={styles.testimonial}>
              <p>"{testimonial.text}"</p>
              <div className={styles.author}>- {testimonial.author}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
