import React, { useState } from "react";
import styles from "./Products.module.css";
import { scrollToCommandaAndFocus } from "../../utils/focusUtilts";

interface Product {
  id: string;
  name: string;
  shortDescription: string;
  description: string;
  material: string;
  dimensions: string;
  price: string;
  image: string;
}

const Products: React.FC = () => {
  const [modalProduct, setModalProduct] = useState<Product | null>(null);

  const products: Product[] = [
    {
      id: "amintire_caligrafica_clasica",
      name: "Amintire Caligrafică Clasică",
      shortDescription: "O piesă intimă și personală",
      description:
        "Descoperă o piesă de artă orientală unică, creată special pentru tine. Maestrul caligraf va traduce, interpreta și picta numele tău pe hârtie Xuan tradițională, surprinzând esența acestuia într-o formă delicată și plină de semnificație. Fiecare detaliu al acestei lucrări reflectă eleganța și profunzimea artei caligrafiei chinezești. Lucrarea va fi expediată într-un tub elegant, cu hârtie de protecție pentru a asigura integritatea sa pe durata transportului. Această piesă poate fi păstrată în tub, ca un obiect de colecție, sau poate fi înrămată și expusă pentru a adăuga un element de rafinament și artă în orice spațiu.",
      material: "Hârtie de orez Xuan",
      dimensions: "69 x 34 cm",
      price: "199 lei",
      image: "/images/amintire_caligrafica_clasica.webp",
    },
    {
      id: "amintire_caligrafica_grand",
      name: "Amintire Caligrafică Grand",
      shortDescription: "O piesă monumentală și personalizată",
      description:
        "Descoperă o operă de artă orientală grandioasă, creată special pentru tine. Maestrul caligraf va traduce, interpreta și picta numele tău pe hârtie Xuan tradițională, surprinzând esența acestuia într-o formă amplă și plină de semnificație. Această lucrare de dimensiuni mari captează eleganța și profunzimea artei caligrafiei chinezești, oferind un impact vizual remarcabil. Lucrarea va fi expediată într-un tub elegant, cu hârtie de protecție, pentru a asigura integritatea sa pe durata transportului. Această piesă poate fi păstrată în tub, ca un obiect de colecție, sau poate fi înrămată și expusă pentru a aduce un element de rafinament și măreție în orice spațiu.",
      material: "Hârtie de orez Xuan",
      dimensions: "69 x 138 cm",
      price: "299 lei",
      image: "/images/amintire_caligrafica_grand.webp",
    },
    {
      id: "pergament_imperial",
      name: "Pergament Imperial",
      shortDescription: "Eleganță atemporală",
      description:
        "Eleganță atemporală pe pergament autentic, evocând manuscrise antice. Caligrafie de lux care îmbină tradiția cu rafinamentul, oferind o experiență tactilă și vizuală unică, perfectă pentru cei care apreciază arta în formele sale cele mai nobile.",
      material: "Pergament premium",
      dimensions: "43 x 119 cm",
      price: "399 lei",
      image: "/images/pergament_imperial.webp",
    },
    {
      id: "armonie_compacta",
      name: "Armonie Compactă",
      shortDescription: "Eleganță delicată",
      description:
        "O piesă de artă elegantă, perfectă pentru a aduce un strop de rafinament în orice spațiu. Acest tablou transformă numele într-o operă de artă contemporană, combinând caligrafia tradițională cu elemente moderne. Dimensiunile compacte îl fac ideal pentru a fi expus în spații mai intime, păstrând totuși un impact vizual remarcabil.",
      material: "Ramă din plastic sau lemn, cu protecție",
      dimensions: "40 x 50 cm",
      price: "349 lei",
      image: "/images/armonie_compacta.webp",
    },
    {
      id: "armonie_echilibrata",
      name: "Armonie Echilibrată",
      shortDescription: "Tradiție modernă",
      description:
        "Un tablou de dimensiuni medii care reușește să echilibreze eleganța tradiției cu inovația modernă. Acest tablou armonizează perfect caligrafia tradițională cu accente contemporane, oferind o prezență vizuală impresionantă, potrivită pentru livinguri sau birouri. Este ideal pentru a fi expus într-un loc central, aducând un plus de personalitate și stil.",
      material: "Ramă din plastic sau lemn, cu protecție",
      dimensions: "50 x 70 cm",
      price: "649 lei",
      image: "/images/armonie_echilibrata.webp",
    },
    {
      id: "armonie_impunatoare",
      name: "Armonie Impunătoare",
      shortDescription: "Prezență spectaculoasă",
      description:
        "O piesă de artă impunătoare, care domină vizual orice încăpere. Acest tablou mare transformă numele într-o operă de artă spectaculoasă, îmbinând caligrafia tradițională cu elemente moderne într-un mod unic. Cu dimensiunile sale generoase, devine punctul central al oricărui spațiu, de la camere de zi spațioase la holuri impresionante. Ideal pentru cei care doresc să facă o declarație artistică puternică.",
      material: "Ramă din plastic sau lemn, cu protecție",
      dimensions: "100 x 150 cm",
      price: "1049 lei",
      image: "/images/armonie_impunatoare.webp",
    },
  ];

  const handleOrderClick = (productId: string, e: React.MouseEvent) => {
    e.stopPropagation();
    const orderSection = document.getElementById("comanda");
    if (orderSection) {
      orderSection.scrollIntoView({ behavior: "smooth" });
      localStorage.setItem("selectedProduct", productId);
      const event = new CustomEvent("productSelected", { detail: productId });
      window.dispatchEvent(event);
      scrollToCommandaAndFocus();
    }
    closeModal();
  };

  const openModal = (product: Product) => {
    setModalProduct(product);
  };

  const closeModal = () => {
    setModalProduct(null);
  };

  return (
    <section id="produse" className={styles.products}>
      <div className={styles.content}>
        <h2>Produsele Noastre</h2>
        <div className={styles.productGrid}>
          {products.map((product) => (
            <div
              key={product.id}
              className={styles.productCard}
              onClick={() => openModal(product)}
            >
              <div className={styles.imageContainer}>
                <img
                  src={product.image}
                  alt={product.name}
                  className={styles.productImage}
                />
              </div>
              <div className={styles.productInfo}>
                <h3>{product.name}</h3>
                <p className={styles.shortDescription}>
                  {product.shortDescription}
                </p>
                <p className={styles.material}>Material: {product.material}</p>
                <p className={styles.dimensions}>
                  Dimensiuni: {product.dimensions}
                </p>
                <p className={styles.description}>{product.description}</p>
                <span className={styles.productPrice}>{product.price}</span>
                <button
                  className={styles.orderButton}
                  onClick={(e) => handleOrderClick(product.id, e)}
                >
                  Comandă Acum
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      {modalProduct && (
        <div className={styles.modal} onClick={closeModal}>
          <div
            className={styles.modalContent}
            onClick={(e) => e.stopPropagation()}
          >
            <span className={styles.closeButton} onClick={closeModal}>
              &times;
            </span>
            <h2>{modalProduct.name}</h2>
            <img
              src={modalProduct.image}
              alt={modalProduct.name}
              className={styles.modalImage}
            />
            <p>{modalProduct.description}</p>
            <p>Material: {modalProduct.material}</p>
            <p>Dimensiuni: {modalProduct.dimensions}</p>
            <p>Preț: {modalProduct.price}</p>
            <button
              className={styles.orderButton}
              onClick={(e) => handleOrderClick(modalProduct.id, e)}
            >
              Comandă Acum
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default Products;
